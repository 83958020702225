// ----------------------------------------
// Northern Tasmania
// ----------------------------------------

// Utilities
import './utils/vh-fix';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';

// Vendor
// import Flickity from 'flickity';
import 'lazysizes/lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/progressive/ls.progressive';

// Flickity - please use `data-flickity` options to configure
import 'flickity';
import 'flickity-as-nav-for';
import 'flickity-bg-lazyload';
import 'flickity-imagesloaded';
import 'flickity-sync';

// Components
import './components/header';
import './components/navigation';
import './components/tabs';
import './components/interactive-map';
import './components/cards';
// import './components/trip-planner';

// Pages
import './pages/landing-page';
import './pages/atdw';

// Fixes initial transitions firing on page load
document.body.classList.remove('preload');

if (window.addressCoords) {
    import('./pages/map-page' /* webpackChunkName: "map-page" */).then(({ default: init }) => {
        init();
    });
}

if (window.interactiveMapAddressCoords) {
    import('./pages/interactive-map-page' /* webpackChunkName: "map-page" */).then(({ default: init }) => {
        init();
    });
}

// if (document.body.classList.contains('entry-trip-planner')) {
//     import('./pages/trip-planner' /* webpackChunkName: "trip-planner" */).then(({ default: init }) => {
//         init();
//     });
// }
